import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from '../../../../app.service';
import { getApiConstants } from '../constants';

export const globalLoadingDisabled = 'globalLoadingDisabled';
export const commonErrorHandlingDisabled = 'commonErrorHandlingDisabled';

export const combineHeadersOptions = (...args: string[]): HttpHeaders => {
  let headers = new HttpHeaders();
  args.forEach(header => {
    headers = headers.append(header, header);
  });
  return headers;
};

export class ServiceInteractionError {
}

export const serviceInteractionHandler: any =
  // eslint-disable-next-line @typescript-eslint/ban-types
  (successHandler: Function, errorActions: any[] = []) => (response: any) =>
    response instanceof ServiceInteractionError
      ? errorActions
      : successHandler(response);

export abstract class AbstractServerInteractionService {
  protected abstract get url(): any;
  protected abstract get http(): HttpClient;
  protected abstract get appService(): AppService;
}

export const baseServiceUrl = 'url';
export const API_CONSTANTS_STUB = getApiConstants(baseServiceUrl);
export const AppServiceStub: any = {
  getApiUrls: () => API_CONSTANTS_STUB,
  getSettings: () => ({})
};
